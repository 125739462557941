<template>
    <div class="login-wrap">
        <div class="content">
            <div class="left"></div>
            <div class="right">
                <div class="login-form">
                    <div class="title">
                        <span>管理员登录</span>
                    </div>
                    <el-form :model="LoginData" label-width="68px" :rules="rules" ref="LoginData">
                        <el-form-item  prop="username">
                            <el-input v-model="LoginData.username" @keyup.enter.native="loginSubmitForm('LoginData')"  placeholder="请输入用户名">
                                <i slot="prefix" class="iconfont">&#xe635;</i>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="LoginData.password" @keyup.enter.native="loginSubmitForm('LoginData')"  show-password placeholder="请输入密码" type="password">
                                <i slot="prefix" class="iconfont">&#xe89f;</i>
                            </el-input>
                        </el-form-item>
                        <el-button type="primary" class="admin-btn" @click="loginSubmitForm('LoginData')" >登录</el-button>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                LoginData: {
                    username: '',
                    password: ''
                },
                rules: {
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                        {min: 2, max: 40, message: '长度在 2 到 40个字符', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {min: 6, max: 25, message: '长度在 6 到 25个字符', trigger: 'blur'}
                    ]
                },
            }
        },
        methods: {
            loginSubmitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData();
                        formData.append('username', this.LoginData.username);
                        formData.append('password', this.LoginData.password);
                        this.$http.axiosPost(this.$api.login, formData, (res) => {
                            // console.log(63,res)
                            if (res.code === 200) {
                                if(res.data.role === 1){
                                    this.$message({
                                        type: 'success',
                                        message: '总理员登录成功！',
                                        duration: 1000,
                                    });
                                    localStorage.setItem('token', res.data.token);
                                    localStorage.setItem('username', res.data.username);
                                    localStorage.setItem('adminIcon', res.data.head_portrait);
                                    localStorage.setItem('role', res.data.role);
                                    this.$router.push('/admin/education/index');
                                }else if (res.data.role === 2){
                                    this.$message({
                                        type: 'success',
                                        message: '学校理员登录成功！',
                                        duration: 1000,
                                    });
                                    localStorage.setItem('token', res.data.token);
                                    localStorage.setItem('username', res.data.username);
                                    localStorage.setItem('adminIcon', res.data.head_portrait);
                                    localStorage.setItem('role', res.data.role);
                                    this.$router.push('/school/education/index');
                                }
                            }else {
                                this.$message({
                                    message: res.message,
                                    duration: 1000,
                                    type: 'error'
                                });
                            }
                        }, (err) => {
                            console.log(err);
                        });
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .login-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-image: url("../assets/img/image/admin-bg.png");
        background-repeat: no-repeat;
        /*background-size: contain;*/
        overflow: auto;
    }
    .header {
        padding: 20px;
    }
    .content {
        width: 960px;
        height:520px;
        margin: 40px auto;
    }
    .left {
        width: 50%;
        height: 100%;
        float: left;
        background-image: url("../assets/img/image/admin-left.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 4px 0 0 4px;
    }
    .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        float: right;
        background-color: #fff;
        border-radius: 0 4px 4px 0;
        .el-button--primary{
            background-color: #1E34E4;
            border-color: #1E34E4;
        }
        .el-button--primary:focus, .el-button--primary:hover {
            background:#4154ea ;
            border-color: #4154ea;
            color: #FFF;
        }
    }
    .login-form {
        width: 400px;
        height:300px;
        margin: 0 auto;
        ::v-deep .el-input__inner {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid #DCDFE6;
        }

        ::v-deep.el-form-item__content{
            margin-left: 0!important;
        }
        i{
            font-size: 20px;
        }
    }
    ::v-deep.el-form-item{
        margin-bottom: 30px;
    }
    .title {
        display: flex;
        justify-content: center;
        margin-bottom:30px;
    }
    .title span {
        font-size: 24px;
        color: #4154ea;
        margin-left: 10px;
    }
    .admin-btn{
        width:100%;
        /*margin-left: 68px;*/
        margin-top: 30px;
    }
</style>